<template>
  <div id="features" class="section section-feature-grey is-medium checkout-conferma">
    <div class="container">
      <div class="content-wrapper">
        <div class="columns is-vcentered">
          <div class="column is-5 is-offset-1">
            <div class="side-feature-text">
              <h3 class="no-margin">Pagamenti Accettati</h3>
              <p class="pt-10">
                Sono accettate le maggiori carte di credito.
              </p>
              <div class="providers">

               <img src="/assets/stripe.png" width="40%">
               <img src="/assets/logo-cc-stripe.png" width="40%"> 

              </div>
            </div>
          </div>
          <!-- Card with icons -->
          <div class="column is-5">
            <div class="flex-card company-types" padding="10px;">
              <a
                class="button primary-btn raised is-fullwidth"
               :href="link"
                >Effettua il Pagamento</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CheckoutSt",
  data() {
    return {
      planid: this.$route.params.id,
      link: this.$store.state.link
    };
  },

  mounted() {
    this.link=this.$store.state.link;
       document.title = "Checkout | BikeProtection";   
  },
  setup() {
    return {};
  },

  computed: {
    getplanid() {
      return this.$route.params.id;
    },
  },
  methods: {

  },
};
</script>

<style   scoped>
  .conference-event-card .card-container {
    height: 100%;
    width: 80% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
</style>