import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Result from '../views/Result.vue';
import Resulterror from '../views/Resulterror.vue';
import Checkout from '../views/Checkout.vue';
import CheckoutSt from '../views/CheckoutSt.vue';

const routes = [
  {
    path: '/:id',
    name: 'Home',
    component: Home
  },
  {
    path: '/result/:id',
    name: 'Result',
    component: Result
  },
  {
    path: '/checkout/:id',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/checkoutst/:id/:url',
    name: 'CheckoutSt',
    component: CheckoutSt
  },
  {
    path: '/resulterror/:id',
    name: 'Resulterror',
    component: Resulterror
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
