<template>
   <plans/>
   <customer />
</template>

<script>
 

import Customer from './../components/Customer.vue'
import Plans from './../components/Plans2.vue'

export default {
  name: 'Home',
  components: {
 
    Plans,
    Customer
  }, 
mounted() {  
    document.title = "Shop | BikeProtection";  
  }, 
 
}
</script>
