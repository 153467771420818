<template>
  <!-- Contact form -->
  <div class="section section-feature-grey">
    <div class="container">
      <div class="section-title has-text-centered">
        <div class="title-wrap">
          <h3 class="title title-alt is-3">Compila i tuoi dati</h3>
          <p class="subtitle is-6"></p>
        </div>
      </div>
      <form id="formgaranzia" v-on:submit="checkout">
        <div class="columns">
          <div class="column is-8 z-index-2">
            <div class="content-wrapper">
              <div class="flex-card simple-shadow">
                <div class="card-body">
                  <div class="content">
                    <div class="columns">
                      <div class="column is-12">
                        <div class="columns is-vcentered">
                          <div class="column is-6">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.nome.$errors.length,
                              }"
                            >
                              <input v-model="state.myform.nome" class="material-input" type="text"/>
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Nome *</label>
                              <small v-if="v$.myform.nome.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.nome.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.cognome.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.cognome"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Cognome *</label>
                              <small v-if="v$.myform.cognome.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.cognome.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-vcentered">
                          <div class="column is-6">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error':
                                  v$.myform.codicefiscale.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.codicefiscale"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Codice Fiscale *</label>
                              <small
                                  v-if="v$.myform.codicefiscale.$error"
                                  class="validation-info error"
                              >
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.codicefiscale
                                  .$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-vcentered">
                          <div class="column is-6">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.email.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.email"
                                  class="material-input"
                                  type="email"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Email *</label>
                              <small v-if="v$.myform.email.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.email.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.telefono.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.telefono"
                                  class="material-input"
                                  type="tel"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Numero telefono *</label>
                              <small v-if="v$.myform.telefono.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.telefono.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-vcentered">
                          <div class="column is-12">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.indirizzo.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.indirizzo"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Indirizzo *</label>
                              <small
                                  v-if="v$.myform.indirizzo.$error"
                                  class="validation-info error"
                              >
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.indirizzo.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                        </div>

                        <div class="columns is-vcentered">
                          <div class="column is-4">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.cap.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.cap"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Cap *</label>
                              <small v-if="v$.myform.cap.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.cap.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.city.$errors.length,
                              }"
                            >
                              <input v-model="state.myform.city" class="material-input" type="text"/>
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Città *</label>
                              <small v-if="v$.myform.city.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.city.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.prov.$errors.length,
                              }"
                            >
                              <input v-model="state.myform.prov" class="material-input" type="text"/>
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Provincia *</label>
                              <small v-if="v$.myform.prov.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.prov.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                        </div>

                        <div class="columns is-vcentered">
                          <div class="column is-4">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.marca.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.marca"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Marca *</label>
                              <small v-if="v$.myform.marca.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.marca.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div
                                class="control-material is-primary required"
                                :class="{
                                'has-error': v$.myform.modello.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.modello"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Modello *</label>
                              <small v-if="v$.myform.modello.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.modello.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div
                                class="control-material is-primary"
                                :class="{
                                'has-error': v$.myform.telaio.$errors.length,
                              }"
                            >
                              <input
                                  v-model="state.myform.telaio"
                                  class="material-input"
                                  type="text"
                              />
                              <span class="material-highlight"></span>
                              <span class="bar"></span>
                              <label>Telaio</label>
                              <small v-if="v$.myform.telaio.$error" class="validation-info error">
                                <small
                                    class="input-errors"
                                    v-for="error of v$.myform.telaio.$errors"
                                    :key="error.$uid"
                                >
                                  <p class="error-msg">{{ error.$message }}</p>
                                </small>
                              </small>
                            </div>
                          </div>
                          <div class="column is-12"></div>
                        </div>

                        <div class="columns is-vcentered">
                          <div class="column is-12">
                            <div class="control-material is-primary">
                              <div class="file1-input-wrapper mt-20">
                                <div class="control field-input-wrapper">
                                  <h4>Carica Documento di Riconoscimento*</h4>
                                  <input
                                      class="inputfile simple-file-input"
                                      accept="image/png, image/jpeg, application/pdf"
                                      type="file"
                                      @change="
                                      handleFilehandleFileUploadDocRiconoscimentoUpload(
                                        $event
                                      )
                                    "
                                      required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="columns is-vcentered">
                          <div class="column is-12">
                            <div class="control-material is-primary">
                              <div class="file1-input-wrapper mt-20">
                                <div class="control field-input-wrapper">
                                  <h4>Carica Foto della tua Bici</h4>
                                  <input
                                      class="inputfile simple-file-input"
                                      accept="image/png, image/jpeg"
                                      type="file"
                                      @change="
                                      handleFilehandleFileUploadFotoUpload(
                                        $event
                                      )
                                    "

                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="columns is-vcentered">
                          <div class="column is-12">
                            <div class="control-material is-primary">
                              <div class="file1-input-wrapper mt-20">
                                <div class="control field-input-wrapper">
                                  <h4>Carica Documento Acquisto<small v-if="this.$store.state.plan === 3">
                                    *
                                  </small></h4>
                                  <input
                                      class="inputfile simple-file-input"
                                      accept="image/png, image/jpeg, application/pdf"
                                      type="file"
                                      @change="
                                      handleFilehandleFileUploadDocAcquistoUpload(
                                        $event
                                      )
                                    "
                                      :required="this.$store.state.plan===3 ? true : false"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-4 z-index-2">
            <div class="cart-summary">
              <div class="summary-header">
                <h2>Riepilogo</h2>
                <br/>
              </div>

              <!--card-->
              <div class="summary-card">
                <div class="order-line">
                  <span>Piano</span>
                  <span>{{ getplanprice }}</span>
                </div>
                <div class="order-line is-hidden">
                  <span>Opzione</span>
                  <span>{{ getplanoptinprice }}</span>
                </div>
                <div class="order-line">
                  <span class="is-total">Totale</span>
                  <span class="is-total">{{ gettotal }}</span>
                </div>
                <div class="order-line">
                  <h4 class="no-margin">
                    <input type="checkbox" class="d-checkbox" v-model="flagviewcodicesconto" />Hai una bici con drive unit Bosch?
                  </h4>
                </div>
                <div class="order-line" v-if="flagviewcodicesconto">
                  <h2>Inserisci il seguente codice nel carrello, per usufruire del <b>10% di sconto</b>.</h2>


                  </div>
                  <div class="order-line" v-if="flagviewcodicesconto"><h4 class="pt-10"><b>BOSCH23</b></h4>
                </div>


            </div>

            <!--Meta Card-->
            <div class="flex-card simple-shadow" v-show="true">
              <div class="card-body">
                <div class="content">
                  <h4 class="no-margin">Pagamenti Accettati</h4>
                  <p class="pt-10">
                    Sono accettate le maggiori carte di credito come metodo di
                    pagamento mediante il gateway di pagamento <b>Stripe</b>.
                  </p>
                  <div class="providers">
                    <table border="0" cellpadding="10" cellspacing="0" align="center">
                      <tr>
                        <td align="center" style="border:0px;">
                          <a href="https://stripe.com/" target="_blank">
                            <img src="/assets/logo-cc-stripe.png"/>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-card simple-shadow">
              <div class="card-body">
                <div class="content">
                  <div class="field">
                    <div class="control">
                      <label class="checkbox-wrap is-medium">
                        <input id="check1" type="checkbox" class="d-checkbox" required/>
                        <span></span>
                        <b>
                          <a
                              href="https://www.bikeprotection.it/wp-content/uploads/2022/01/BIKE_PROTECTION_SetInformativo_2022.pdf"
                              target="_blank"
                          >Accetto Termini e Condizioni</a>
                        </b>
                      </label>
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <label class="checkbox-wrap is-medium">
                        <input id="check1" type="checkbox" class="d-checkbox" required/>
                        <span></span>
                        <b>
                          <a
                              href="https://www.bikeprotection.it/privacy-policy"
                              target="_blank"
                          >Accetto Privacy</a>
                        </b>
                      </label>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div class="checkout-action">
              <button
                  type="submit"
                  class="button primary-btn button-cta btn-align no-lh is-bold raised is-fullwidth shop-conferma"
              >Conferma
              </button>
            </div>
            <div class="flex-card simple-shadow" style="margin-top:30px;">
              <div class="card-body">
                <div class="content">
                  <p>All'interno di questo sito sono proposti dei prodotti assicurativi in collaborazione con <b>Europ
                    Assistance Italia S.p.A</b></p>
                  <p class="pt-10">
                    <a href="https://www.europassistance.it/" target="_blank">
                      <img src="/assets/EA.png"/>
                    </a>
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </form>

    <div class="checkout-action" style="margin-top: 20px"></div>
  </div>
  </div>

  <section id="cta" class="section is-medium section-feature-grey">
    <div class="container">
      <div class="content">
        <div class="columns is-vcentered">
          <div class="column is-10 is-offset-1">
            <div id="paypalbutton" v-show="flagviewpaypalbuttons"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import {reactive} from "vue";
import {loadScript} from "@paypal/paypal-js";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  maxLength,
  numeric,
} from "@vuelidate/validators";

export default {
  name: "Customer",
  props: {
    msg: String,
  },
  setup() {
    const state = reactive({
      myform: {
        email: "",
        nome: "",
        cognome: "",
        cap: "",
        codicefiscale: "",
        telefono: "",
        city: "",
        prov: "",
        indirizzo: "",
        telaio: "",
        modello: "",
        marca: "",
        filefoto: "",
        filedocriconoscimento: "",
        filedocacquisto: "",
      },
    });
    const rules = {
      myform: {
        email: {required, email},
        nome: {required},
        cognome: {required},
        cap: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        codicefiscale: {required},
        telefono: {required, numeric},
        city: {required},
        prov: {required, minLength: minLength(2), maxLength: maxLength(2)},
        indirizzo: {required},
        modello: {required},
        telaio: {required},
        marca: {required},
      },
    };

    const v$ = useVuelidate(rules, state);

    return {state, v$};
  },
  data() {
    return {
      counter: 0,
      flagviewcodicesconto: false,
      flagviewpaypalbuttons: false,
      myform: {
        nome: "",
        cognome: "",
        codicefiscale: "",
        email: "",
        telefono: "",
        numeroserie: "",
        dataacquisto: "",
        note: "",
        documentoacquisto: "",
        datanascita: null,
        luogonascita: "",
        provnascita: "",
        sex: "",
        indirizzo: "",
        cap: "",
        city: "",
        prov: "",
        optionprice: 0,
        planprice: 0,
        totalprice: 0,
        plan: 1,
        dealeroption: false,
        dealercode: "",
        marca: "",
        modello: "",
        telaio: "",
        filefoto: "",
        filedocriconoscimento: "",
        filedocacquisto: "",
        filefotoid: "",
        filedocacquistoid: "",
        filedocriconoscimentoid: "",
      },
    };
  },

  mounted() {
    this.counter = this.$store.state.count;
    console.log(this.$store.state.count);
    //this.loadPaypal();
  },
  computed: {
    contatore() {
      console.log(this.$store.state.count);
      return this.$store.state.count;
    },
    getplan() {
      return this.$store.state.plan;
    },
    getlink() {
      return this.$store.state.link;
    },
    getplanoptinprice() {
      return this.$store.state.optionprice;
    },
    getplanprice() {
      return this.$store.state.planprice;
    },
    getdealercode() {
      return this.$store.state.dealercode;
    },
    getstart() {
      return this.$store.state.start;
    },
    getdealeroption() {
      return this.$store.state.dealeroption;
    },
    getdealercodefinded() {
      return this.$store.state.ricercadealer;
    },
    gettotal() {
      return this.$store.state.optionprice + this.$store.state.planprice;
    },
    getplancode() {
      //LIGHT
      if (this.$store.state.plan == 1 && this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-8EE23812LM087372NMF5J26Y");
      if (this.$store.state.plan == 1 && !this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-3NX11174B2190592LMF5J7KQ");
      //MEDIUM
      if (this.$store.state.plan == 2 && this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-7AM86882FR047590PMF5J4AA");
      if (this.$store.state.plan == 2 && !this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-97C31920UP695152XMGABANQ");
      //FULL
      if (this.$store.state.plan == 3 && this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-5A326422J89774315MF5J5YY");
      if (this.$store.state.plan == 3 && !this.$store.state.dealeroption)
        this.$store.commit("updateplancode", "P-4SD40369WN482623MMF5KIUA");
      return this.$store.state.plancode;
    },
    getplancodestripe() {
      //LIGHT
      if (this.$store.state.plan == 1 && this.$store.state.dealeroption) {
        this.$store.commit("updateplancode", "prod_KZV2moVa4z7Mkr");
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/9AQeYsg3003Adqw8wD "
        );
      }
      if (this.$store.state.plan == 1 && !this.$store.state.dealeroption) {
        this.$store.commit("updateplancode", "prod_KZV4PZBpud2Fht");
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/9AQeYsg3003Adqw8wD"
        );
      }
      //MEDIUM
      if (this.$store.state.plan == 2 && this.$store.state.dealeroption) {
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/3cs7w07wu3fMdqw008"
        );
        this.$store.commit("updateplancode", "prod_KZV20xCj2ztbL1");
      }
      if (this.$store.state.plan == 2 && !this.$store.state.dealeroption) {
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/3cs7w07wu3fMdqw008"
        );
        this.$store.commit("updateplancode", "prod_KZV5nmD8LgpdZX");
      }
      //FULL
      if (this.$store.state.plan == 3 && this.$store.state.dealeroption) {
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/cN2eYsbMKaIebio4gp"
        );
        this.$store.commit("updateplancode", "prod_KZV3EONxotFEIS");
      }
      if (this.$store.state.plan == 3 && !this.$store.state.dealeroption) {
        this.$store.commit(
            "updatelink",
            "https://buy.stripe.com/cN2eYsbMKaIebio4gp"
        );
        this.$store.commit("updateplancode", "prod_KZV5hmBltCFzIq");
      }
      return this.$store.state.plancode;
    },
  },

  watch: {
    // whenever question changes, this function will run
  },
  methods: {
    handleFilehandleFileUploadFotoUpload(event) {
      this.myform.filefoto = event.target.files[0];
      console.log(event.target.files[0]);
    },
    handleFilehandleFileUploadDocRiconoscimentoUpload(event) {
      this.myform.filedocriconoscimento = event.target.files[0];
      console.log(event.target.files[0]);
    },
    handleFilehandleFileUploadDocAcquistoUpload(event) {
      this.myform.filedocacquisto = event.target.files[0];
      console.log(event.target.files[0]);
    },

    async uploadfiles() {
      let formData = new FormData();
      let formData2 = new FormData();
      let formData3 = new FormData();
      let myapp = this;

      formData.append("file", this.myform.filefoto);
      //formData2.append("file", this.myform.filedocacquistoid);
      //formData3.append("file", this.myform.filedocriconoscimentoid);

      await axios
          .post(
              "https://api.bikerprotection.it/files/?access_token=yKzZJCNUEc9A55wqYf8pf8TU5",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          )
          .then(function (response) {
            console.log(response);
            console.log(response.data.data.id);

            myapp.myform.filefotoid = response.data.data.id;
            console.log("filefotoid " + myapp.myform.filefotoid);

          })
          .catch(function (error) {
            console.log(error);
          });

      formData2.append("file", myapp.myform.filedocacquistoid);
      await axios
          .post(
              "https://api.bikerprotection.it/files/?access_token=yKzZJCNUEc9A55wqYf8pf8TU5",
              formData2,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          )
          .then(function (response) {
            console.log(response);
            console.log(response.data.data.id);

            myapp.myform.filedocacquistoid = response.data.data.id;
            console.log("filedocriconoscimento " + myapp.myform.filedocacquistoid);

            //myapp.senddatatodirectus();
          })
          .catch(function (error) {
            console.log(error);
          });


      formData3.append("file", myapp.myform.filedocriconoscimentoid);
      await axios
          .post(
              "https://api.bikerprotection.it/files/?access_token=yKzZJCNUEc9A55wqYf8pf8TU5",
              formData3,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          )

          .then(function (response) {
            console.log(response);
            console.log(response.data.data.id);

            myapp.myform.filedocriconoscimentoid = response.data.data.id;
            console.log(
                "filedocriconoscimentoid " + myapp.myform.filedocriconoscimentoid
            );


          })
          .catch(function (error) {
            console.log(error);
          });

    },

    senddatatodirectus() {

      var data = JSON.stringify({
        Nome: this.state.myform.nome,
        Cognome: this.state.myform.cognome,
        CodiceFiscale: this.state.myform.codicefiscale,
        Email: this.state.myform.email,
        Telefono: this.state.myform.telefono,
        Indirizzo: this.state.myform.indirizzo,
        Cap: this.state.myform.cap,
        Citta: this.state.myform.city,
        Prov: this.state.myform.prov,
        Pagato: 0,
        DealerScelto: this.getdealercode,
        Prodotto: this.getplancode,
        Costo: this.gettotal,
        Piano: this.getplan,
        Modello: this.state.myform.modello,
        Marca: this.state.myform.marca,
        Telaio: this.state.myform.telaio,
        Foto: this.myform.filefotoid,
        DocAcquisto: this.myform.filedocacquistoid,
        DocRiconoscimento: this.myform.filedocriconoscimentoid,
      });

      const headers = {
        "Content-Type": "application/json",
      };

      axios
          .post(
              "https://api.bikerprotection.it/items/customers?access_token=yKzZJCNUEc9A55wqYf8pf8TU5",
              data,
              {headers}
          )
          .then((response) => {
            console.log(response.data);
            this.sendemail();
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    },

    async savedata() {
      await this.uploadfiles();
      //setTimeout(() => { this.senddatatodirectus(); }, 2000);
      await this.senddatatodirectus();
    },

    sendemailOLD() {
      const headers = {
        "Content-Type": "text/plain",
      };

      const params = {
        code: "TpGY0K0vIccpZbSv7xQQ5Wei2GMpfEfLntEKkE7u49ueDUrEXxyzDA==",
        emailto: this.state.myform.email,
        firstname: this.state.myform.nome,
        lastname: this.state.myform.cognome,

      };

      axios
          .get(
              "https://emailer-pet-azfunc.azurewebsites.net/api/EmailPeTHttpTrigger",
              {params},
              {headers}
          )
          .then((response) => {
            this.users = response.data;
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    },


    sendemail() {

      const headers = {
        "Content-Type": "text/plain",
      };

      const dataoggi = new Date();

      const params = {
        //code: "TpGY0K0vIccpZbSv7xQQ5Wei2GMpfEfLntEKkE7u49ueDUrEXxyzDA==",
        idplan: this.getplan,
        emailto: this.state.myform.email,
        nome: this.state.myform.nome,
        cognome: this.state.myform.cognome,
        //nato : this.state.myform.datanascita,
        nato: " ",
        luogonascita: this.state.myform.city,
        residenza: this.state.myform.indirizzo,
        citta: this.state.myform.city,
        cap: this.state.myform.cap,
        prov: this.state.myform.prov,
        tax: this.state.myform.codicefiscale,
        //datastart: this.state.myform.dataacquisto,
        datastart: dataoggi.toLocaleDateString('it-IT')
      };

      console.log(params);

      axios
          .get(
              "https://ptservices.bikerprotection.it/api/services/GenerateSendMAD",
              {params},
              {headers}
          )
          .then((response) => {
            this.users = response.data;
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
    },

    loadPaypal() {
      //ID PAYPAL UFFICIALE
      //const ClientID = "AXlETLzJl7lhlB5HopxZwm4K6zXji0o-COdJMIfOWkkHfeadhPraWH-zT0FPhkCN_ezFJ8qz7uapWUfQ";
      //ID for TEST PAYPAL RedConsultingSandbox
      //const ClientID = "AcWuuOORZhoKDT_ujNEZu4g5aeVaDhycEOWrE7-ZlXTqGwEXV31f1LjOUu9KApoF4yli1yHNlMGaApgr";
      var navigate = this.$router;
      var planid = this.getplancode;
      loadScript({
        "client-id":
            "AcWuuOORZhoKDT_ujNEZu4g5aeVaDhycEOWrE7-ZlXTqGwEXV31f1LjOUu9KApoF4yli1yHNlMGaApgr",
        currency: "EUR",
        locale: "it_IT",
        vault: "true",
        intent: "subscription",
      })
          .then((paypal) => {
            paypal
                .Buttons({
                  createSubscription: function (data, actions) {
                    return actions.subscription.create({
                      plan_id: planid,
                    });
                  },

                  onApprove: function (data, actions) {
                    console.log(actions);
                    console.log(data);
                    console.log(data.subscriptionID); // You can add optional success message for the subscriber here
                    navigate.push({
                      name: "Result",
                      params: {id: data.subscriptionID},
                    });
                    //console.log(order);
                    // This function captures the funds from the transaction.
                    // navigate.push({
                    //   name: "ReturnPaypal",
                    //   params: {
                    //     msg: data.subscriptionID,
                    //     msgerror: "",
                    //     type: "0",
                    //   },
                    // });
                  },
                  onError: function (err) {
                    console.error("PAYPAL error from the onError callback", err);
                    // For example, redirect to a specific error page
                    //window.location.href = "/your-error-page-here?" + err;
                    navigate.push({
                      name: "Resulterror",
                      params: {id: err},
                    });
                  },
                })
                .render("#paypalbutton")
                .catch((error) => {
                  console.error("failed to render the PayPal Buttons", error);
                });
          })
          .catch((error) => {
            console.error("failed to load the PayPal JS SDK script", error);
          });
    },

    checkout(e) {
      e.preventDefault(); // it prevent from page reload
      console.log("Checkout");

      this.v$.$validate(); // checks all inputs
      console.log("--- " + this.getdealercode);
      if (
          !this.getstart &&
          !this.getdealeroption &&
          (this.getdealercode === "0" || !this.getdealercodefinded)
      ) {
        alert("Devi inserire il codice del dealer.");
      } else {
        if (!this.v$.$error) {
          // this.$router.push({
          //   name: "Checkout",
          //   params: { id: this.getplancode },
          // });
          this.savedata();
          this.$router.push({
            name: "CheckoutSt",
            params: {id: this.getplancodestripe, url: this.getlink},
          });
        } else {
          alert("Controlla i dati inseriti. Ci sono degli errori.");
        }
        //this.flagviewpaypalbuttons = !this.flagviewpaypalbuttons;
      }
    },
  },
};
</script>

