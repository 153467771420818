<template>
  <div id="features" class="section section-feature-grey is-medium">
    <div class="container">
      <div class="content-wrapper">
        <div class="columns is-vcentered">
          <div class="column is-5 is-offset-1">
            <div class="side-feature-text">
              <h3 class="no-margin">Pagamenti Accettati</h3>
              <p class="pt-10">
                Sono accettate le seguenti tipologie di pagamento.
              </p>
              <div class="providers">
                  <!-- PayPal Logo --><table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_SbyPP_mc_vs_dc_ae.jpg" border="0" alt="PayPal Acceptance Mark"></a></td></tr></table><!-- PayPal Logo -->
                 
              </div>
            </div>
          </div>
          <!-- Card with icons -->
          <div class="column is-5">
            <div class="flex-card company-types">
              <div id="paypalbutton"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
export default {
  name: "Checkout",
  data() {
    return {
      planid: this.$route.params.id,
    };
  },

  mounted() {
    this.loadPaypal();
  },
  setup() {
    return {};
  },

  computed: {
    getplanid() {
      return this.$route.params.id;
    },
  },
  methods: {
    loadPaypal() {
      var navigate = this.$router;
      var piano = this.planid;
      //TEST
      //KEY TEST AcWuuOORZhoKDT_ujNEZu4g5aeVaDhycEOWrE7-ZlXTqGwEXV31f1LjOUu9KApoF4yli1yHNlMGaApgr
      //KEY UFFICIALE AXlETLzJl7lhlB5HopxZwm4K6zXji0o-COdJMIfOWkkHfeadhPraWH-zT0FPhkCN_ezFJ8qz7uapWUfQ
      piano = "P-2CC97929XR912302XMF7M4FQ";
      loadScript({
        "client-id":
          "AcWuuOORZhoKDT_ujNEZu4g5aeVaDhycEOWrE7-ZlXTqGwEXV31f1LjOUu9KApoF4yli1yHNlMGaApgr",
        currency: "EUR",
        locale: "it_IT",
        vault: "true",
        intent: "subscription",
      })
        .then((paypal) => {
          paypal
            .Buttons({
              createSubscription: function (data, actions) {
                return actions.subscription.create({
                  plan_id: piano,
                });
              },

              onApprove: function (data, actions) {
                console.log(actions);
                console.log(data);
                console.log(data.subscriptionID); // You can add optional success message for the subscriber here
                navigate.push({
                  name: "Result",
                  params: { id: data.subscriptionID },
                });
                //console.log(order);
                // This function captures the funds from the transaction.
                // navigate.push({
                //   name: "ReturnPaypal",
                //   params: {
                //     msg: data.subscriptionID,
                //     msgerror: "",
                //     type: "0",
                //   },
                // });
              },
              onError: function (err) {
                console.error("PAYPAL error from the onError callback", err);
                // For example, redirect to a specific error page
                //window.location.href = "/your-error-page-here?" + err;
                navigate.push({
                  name: "Resulterror",
                  params: { id: err },
                });
              },
            })
            .render("#paypalbutton")
            .catch((error) => {
              console.error("failed to render the PayPal Buttons", error);
            });
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
    },
  },
};
</script>

<style   scoped>
  .conference-event-card .card-container {
    height: 100%;
    width: 80% !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
</style>