<template>
  <div id="features" class="section section-feature-grey is-medium">
        <div class="container">
             

            <!-- CTA section -->
            <div class="pt-80 pb-80">
                <div class="conference-event-card">
                    <div class="card-container">
                        <div class="card-container-content">
                            <div class="text">
                                <h1>Errore da comunicare</h1>
                                <p>
                               {{ gettransactioncode }}
                                </p>
                              <router-link to="/" class="button button-cta btn-align primary-btn">Home</router-link>
                            </div>
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Resulterror',
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {
    gettransactioncode() {
      return this.$route.params.id;
    },
  },
};
</script>

<style   scoped>
</style>