<template>
      <!-- Hero and nav -->
    <div class="hero is-theme-primary">

        <nav class="navbar navbar-wrapper navbar-fade navbar-light is-transparent">
            <div class="container">
                <!-- Brand -->
                <div class="navbar-brand">
                    <a class="navbar-item" href="/">
                        <img class="light-logo" src="https://www.bikeprotection.it/wp-content/uploads/2021/10/logo-BikeProtection-600.png" alt="" height="88">
                        <img class="dark-logo switcher-logo" src="https://www.bikeprotection.it/wp-content/uploads/2021/10/logo-BikeProtection-600.png" alt="" height="88">
                    </a>



                    <!-- Responsive toggle -->
                    <div class="custom-burger" data-target="">
                        <a id="" class="responsive-btn" href="javascript:void(0);">
                            <span class="menu-toggle">
                                <span class="icon-box-toggle">
                                    <span class="rotate">
                                        <i class="icon-line-top"></i>
                                        <i class="icon-line-center"></i>
                                        <i class="icon-line-bottom"></i>
                                    </span>
                            </span>
                            </span>
                        </a>
                    </div>
                    <!-- /Responsive toggle -->
                </div>

                <!-- Navbar menu -->
                <div class="navbar-menu">
                    <!-- Navbar Start -->
                    <div class="navbar-start">
                        <!-- Navbar item -->
                        <a class="navbar-item is-slide" href="/">
                            Home
                        </a>

                    </div>

                    <!-- Navbar end -->
                    <div class="navbar-end">

                    </div>
                </div>
            </div>
        </nav>

    </div>
      <router-view></router-view>

     <footer class="footer footer-light-left">
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column is-6">
                    <div class="mb-10">
                        <img class="small-footer-logo" src="https://www.bikeprotection.it/wp-content/uploads/2021/10/logo-BikeProtection-600.png" alt="">
                    </div>
                    <div>
                        <span class="moto">© 2021-2023 P&T INSURANCE BROKER SRL – Via Cantore 41/3B, 16149 Genova – P. IVA: 02241140991 – Iscrizione Rui Sez. “B”, n. B000485978 – REA GE-471174</span>
                        <br/>
                        <!-- <span class="moto">By</span>
                        <nav class="level is-mobile mt-20">
                            <div class="level-left level-social">

                            </div>
                        </nav> -->
                    </div>
                </div>
                <div class="column">
                    <div class="footer-nav-right">
                        <!-- <a class="footer-nav-link" href="#">Home</a>
                        <a class="footer-nav-link" href="#">Sito</a> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>

</template>

<script>


export default {
  name: 'App',
  components: {


  },
   mounted() {

  },
}
</script>

<style>
#app {

  font-family: 'Roboto Condensed', 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.pricing-wrapper .pricing-box .price .price-number:after {
    content: "PER ANNO";
    position: absolute;
    top: 6px;
    right: 40px;
    font-size: .8rem;
    font-weight: 400;
    color: #999;
    text-align: left;
    text-transform: uppercase;
    max-width: 50px;
    line-height: 1.2;
}


.cart-summary .summary-card .order-line span:nth-child(2):before {
    content: "EUR ";
}

.navbar-wrapper .navbar-brand img {
    height: 100px !important;
}
.navbar-item img {
    max-height: 100px !important;
}

.hover-cards .icon-card-hover {
    height: 250px !important;
}

.conference-event-card {
    height: 100% !important;
}
.button.primary-btn {
    outline: none;
    border-color: #659d33;
    background-color: #659d33;
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.navbar-menu{display:none}
.navbar>.container{justify-content:center}
.hero .navbar{height:fit-content !important; background:white !important;}

 .navbar-wrapper>.container {
    min-height: 5rem;
}

.navbar-wrapper {
    width: 100%;
    height: 6rem !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    -webkit-transition: all .4s;
    transition: all .4s;
    padding: 20px;
}
</style>
