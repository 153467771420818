import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      count: 0,
      year: 0,
      type: "",
      plan: 0,
      totalprice: 0,
      dealeroption: false,
      dealercode: "0",
      optionprice: 0,
      planprice: 0,
      plancode: "",
      ricercadealer: false,
      start: true,
      link: "",
    };
  },

  mutations: {
    increment(state) {
      state.count++;
    },
    updateyear(state, y) {
      state.year = y;
    },
    updatericercadealer(state, y) {
      state.ricercadealer = y;
    },
    updatetype(state, t) {
      state.type = t;
    },
    updateplan(state, p) {
      state.plan = p;
      if (p == 1) state.planprice = 129;
      if (p == 2) state.planprice = 189;
      if (p == 3) state.planprice = 249;
    },
    updateoption(state, p) {
      state.dealeroption = p;
      state.start = false;
      if (!p) state.optionprice = 10;
      else state.optionprice = 0;
      console.log(state.optionprice);
    },
    updateplancode(state, t) {
      state.plancode = t;
    },
    updatedealercode(state, t) {
      state.dealercode = t;
    },
    updatelink(state, t) {
      state.link = t;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getPlanPrice(state) {
      return state.planprice;
    },
    getPlanLink(state) {
      return state.link;
    },
    getRicercaDealer(state) {
      return state.ricercadealer;
    },
    getPlanCode(state) {
      //LIGHT
      if (state.plan == 1 && !state.dealeroption) {
        state.plancode = "price_1O8HoEC4sc0b9HYcoHuiosdk";
        state.link = "https://buy.stripe.com/7sIeYs9ECaIecms6oy";
      }
      if (state.plan == 1 && state.dealeroption) {
        state.plancode = "price_1O8HoEC4sc0b9HYcoHuiosdkr";
        state.link = "https://buy.stripe.com/7sIeYs9ECaIecms6oy";
      }
      //MEDIUM
      if (state.plan == 2 && !state.dealeroption) {
        state.plancode = "price_1O8HrAC4sc0b9HYcYTVCUxFs";
        state.link = "https://buy.stripe.com/eVa03y8Ay9EagCI6oz";
      }
      if (state.plan == 2 && state.dealeroption) {
        state.plancode = "price_1O8HrAC4sc0b9HYcYTVCUxFs";
        state.link = "https://buy.stripe.com/eVa03y8Ay9EagCI6oz";
      }
      //FULL
      if (state.plan == 3 && state.dealeroption) {
        state.plancode = "price_1O8HsXC4sc0b9HYcms31B6G5";
        state.link = "https://buy.stripe.com/00g2bGcQO3fMeuAbIU";
      }
      if (state.plan == 3 && !state.dealeroption) {
        state.plancode = "price_1O8HsXC4sc0b9HYcms31B6G5";
        state.link = "https://buy.stripe.com/00g2bGcQO3fMeuAbIU";
      }

      return state.plancode;
    },
  },
});
