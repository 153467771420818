<template>
  <div id="features" class="section section-feature-grey is-medium">
    <div class="container">
      <!-- CTA section -->
      <div class="pt-80 pb-80" style="padding:5px;">
        <div class="conference-event-card">
          <div class="card-container">
            <div class="card-container-content" style="padding:10px;">
              <div class="text">
                <h1>Grazie.</h1>
             <hr>
                <h4>Attiva il pacchetto Bikeprotection con un bonifico a: </h4>
              
                  <!-- Codice transazione: <b>{{ gettransactioncode }}</b> -->
                    <h4>P & T Insurance Broker Srl 
IBAN IT 71 R 02008 01438 000103329053</h4>
<br/>
  <h4>Causale: Acquisto "Pacchetto scelto" 
( sostituire pacchetto scelto con il nome del pacchetto acquistato )</h4>
<br/>
  <h4>Una volta effettuato il bonifico riceverai una e-mail  da: <b>polizze@bikeprotection.it</b> con tutte le informazioni. Ti ricordiamo che le garanzie sono attive dal 15° giorno successivo alla data di  pagamento.
                </h4>
<br><br>
                <router-link
                  to="/"
                  class="button button-cta btn-align primary-btn"
                  >Home</router-link
                >
              </div>
            </div>
          </div>
          <div
            class="photo has-background-image"
            data-background="https://images.unsplash.com/photo-1635510238241-a53e14fe659e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnxwM2tLeDFXUE1Nb3x8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
            data-demo-background="https://images.pexels.com/photos/3160996/pexels-photo-3160996.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          >
            <img
              src="https://images.unsplash.com/photo-1635510238241-a53e14fe659e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MnxwM2tLeDFXUE1Nb3x8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Result",
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {
    gettransactioncode() {
      return this.$route.params.id;
    },
  },
};
</script>

<style   scoped>
</style>