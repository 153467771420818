<template>
  <div id="pricing" class="section">
    <div class="container">
      <div class="section-title has-text-centered">
        <div class="title-wrap">
          <h3 class="title title-alt is-3">I pacchetti di BikeProtection</h3>
          <p class="subtitle is-6">
            Proteggi la tua e-bike con la nostra offerta dedicata
          </p>
        </div>
      </div>

      <div class="pricing-wrapper">
        <div class="columns">
          <div class="column is-4">
            <div class="pricing-box has-ribbon">
              <div v-if="getplan == 1" class="ribbon is-primary is-large">
                TUA SCELTA
              </div>
              <img
                src="https://www.bikeprotection.it/wp-content/uploads/2021/11/Bike-Protection-Light-2.png"
                alt=""
              />
              <h3>Light</h3>
              <p></p>
              <div class="price">
                <div class="price-number">
                  <span>129,00 EUR</span>
                </div>
              </div>
              <p></p>
              <a
                class="button primary-btn raised is-fullwidth shop-light"
                @click="chooseplan(1)"
                >Acquista</a
              >
            </div>
          </div>
          <div class="column is-4">
            <div class="pricing-box has-ribbon">
              <div v-if="getplan == 2" class="ribbon is-primary is-large">
                TUA SCELTA
              </div>
              <img
                src="https://www.bikeprotection.it/wp-content/uploads/2021/10/Bike-Protection-medium.png"
                alt=""
              />
              <h3>Medium</h3>
              <p></p>
              <div class="price">
                <div class="price-number">
                  <span>189,00 EUR</span>
                </div>
              </div>
              <p></p>
              <a
                class="button primary-btn raised is-fullwidth shop-medium"
                @click="chooseplan(2)"
                >Acquista</a
              >
            </div>
          </div>
          <div class="column is-4">
            <div class="pricing-box has-ribbon">
              <div v-if="getplan == 3" class="ribbon is-primary is-large">
                TUA SCELTA
              </div>
              <img
                src="https://www.bikeprotection.it/wp-content/uploads/2021/10/Bike-Protection-full.png"
                alt=""
              />
              <h3>Full - FURTO INCLUSO</h3>
              <p></p>
              <div class="price">
                <div class="price-number">
                  <span>249,00 EUR</span>
                </div>
              </div>
              <p></p>
              <a
                class="button primary-btn raised is-fullwidth shop-full"
                @click="chooseplan(3)"
                >Acquista</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="is-centered is-hidden">
        <div class="columns is-vcentered hover-cards">
          <div class="column is-12">
            <div class="columns is-vcentered">
              <div class="column is-8 is-offset-0">
                <div
                  class="flex-card icon-card-hover second-card light-bordered"
                >
                  <h3 class="card-title is-clean">Opzione</h3>
                  <p class="card-description">
                    E’ possibile far spedire ed installare il Sigillo
                    direttamente dal tuo Concessionario.
                  </p>
                  <br />

                  <div class="field">
                    <input
                      id="switchMedium"
                      type="checkbox"
                      name="switchMedium"
                      class="switch is-medium"
                      v-model="myformplan.dealeroption"
                      @click="chooseoption()"
                    />
                    <label for="switchMedium"
                      >Installazione Concessionario</label
                    >
                  </div>
                </div>
              </div>
              <div class="column is-4 is-offset-0">
                <div class="info-block" v-show="myformplan.dealeroption">
                  <h4>Inserisci il codice del dealer</h4>
                  <br />
                  <div
                    class="control-material is-primary"
                    v-show="myformplan.dealeroption"
                  >
                    <input
                      class="material-input"
                      :class="{
                        'is-disabled': !myformplan.dealeroption,
                      }"
                      v-model="myformplan.dealercode"
                      type="text"
                      @change="changevaluedealer"
                    />
                    <span class="material-highlight"></span>
                    <span class="bar"></span>
                    <label>Codice Dealer *</label>
                  </div>
                  <div v-show="flagricercadealer">
                    <h5>{{ nomedealer }}</h5>
                  </div>
                  <div v-show="!flagricercadealer">Codice dealer errato.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Plans2",
  props: {
    msg: String,
  },
  setup() {
    return {};
  },
  data() {
    return {
      myformplan: {
        optionprice: 0,
        planprice: 0,
        totalprice: 0,
        plan: 1,
        dealeroption: false,
        dealercode: "0",
      },
      flagricercadealer: true,
      nomedealer: "",
    };
  },
  mounted() {
    console.log("mounted");
    this.myformplan.plan = this.$route.params.id;
    console.log(this.myformplan.plan);
    this.$store.commit("updateplan", this.$route.params.id);
    console.log(this.$el.textContent); // I'm text inside the component.
  },
  methods: {
    name() {},
    cart() {},
    changevaluedealer() {
      //http://20.113.32.15:8055/items/dealers/?filter[dealer][_eq]=2&access_token=yKzZJCNUEc9A55wqYf8pf8TU5

      axios
        .get(
          'https://api.bikerprotection.it/items/dealers/?filter={"dealer":{"_eq":"' +
            this.myformplan.dealercode +
            '"}}&access_token=yKzZJCNUEc9A55wqYf8pf8TU5'
        )

        .then((response) => {
          console.log(JSON.stringify(response.data.data));
          const isDataAvailable =
            response.data.data && response.data.data.length;
          if (isDataAvailable) {
            console.log("solo in ramo true");
            this.flagricercadealer = true;
            this.nomedealer = response.data.data[0].denominazione;
            this.$store.commit("updatericercadealer", this.flagricercadealer);
          } else {
            console.log("solo in ramo false");
            this.flagricercadealer = false;
            this.nomedealer = "";
            this.$store.commit("updatericercadealer", this.flagricercadealer);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("updatedealercode", this.myformplan.dealercode);
    },

    chooseoption() {
      console.log(this.myformplan.dealeroption);
      if (this.myformplan.dealeroption) {
        this.$store.commit("updateoption", this.myformplan.dealeroption);
      } else {
        this.$store.commit("updateoption", this.myformplan.dealeroption);
        this.myformplan.dealercode = "0";
        this.flagricercadealer = true;
        this.nomedealer = "";
        this.$store.commit("updatericercadealer", this.flagricercadealer);
        this.$store.commit("updatedealercode", this.myformplan.dealercode);
      }
    },
    chooseplan(plan) {
      this.myformplan.plan = plan;
      this.$store.commit("updateplan", plan);
      console.log(plan);

      this.myformplan.plan = this.$store.state.plan;
      this.myformplan.planprice = this.$store.state.planprice;
      this.myformplan.optionprice = this.$store.state.optionprice;
    },
  },
  computed: {
    getyear() {
      return this.$store.state.year;
    },
    getplan() {
      return this.$store.state.plan;
    },

    gettype() {
      return this.$store.state.type;
    },
  },
};
</script>

<style  scoped>
  .pricing-wrapper {
    padding: 30px 0;
    max-width: 100% !important;
    margin: 0 auto;
  }

  .pricing-wrapper .pricing-box > img {
    display: block;
    max-width: 100% !important;
    margin: 0 auto;
  }
</style>
